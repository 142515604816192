import React,{useState,useEffect} from 'react';
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInstagram,
    faLinkedin
  } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt,faEnvelope } from '@fortawesome/free-solid-svg-icons'
const FooterIcon = styled.div`
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:1;
    cursor:pointer;
`;
const FooterIconSingle = styled.div`
    position:relative;
    margin-right:20px;
    width:40px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 479px) {
        margin-right:20px;
    }
`;

const FooterIconOverlay = styled.div`
    width:${props => props.hoverState? '100%':'0%'};
    height:${props => props.hoverState? '100%':'0%'};
    opacity:${props => props.hoverState? '1':'0'};
    border-radius:5px;
    background:rgba(255,255,255,0.35);
    position:absolute;
    transition:all 0.3s ease;
    @media screen and (max-width: 479px) {
        width:100%;
        height:100%;
        opacity:1;
    }
`;
const FooterLink = styled.a`
  width:auto;
  height:auto;
  position:relative;
  text-decoration:none;
  color:inherit;
  font-size:25px;
`;
const FooterIconComponent = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverIn= () =>{
        setHoverState(true)
    }
    const toggleHoverOut= () =>{
        setHoverState(false)
    }
    useEffect(() => {
        setHoverState(false)
      }, []);
    return (
        <FooterIconSingle>            
                <FooterIcon onMouseOver={toggleHoverIn} onMouseOut={toggleHoverOut}>
                    {props.icon==="faPhoneAlt"?
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                    :props.icon==="faEnvelope"?
                    <FooterLink href={"mailto:" + props.to} target="_blank">
                        <FontAwesomeIcon icon={faEnvelope}/>  
                    </FooterLink>  
                    :props.icon==="faLinkedin"?
                    <FooterLink href={props.to} target="_blank">
                        <FontAwesomeIcon icon={faLinkedin}/>
                    </FooterLink>                        
                    :props.icon==="faInstagram"?
                        <FontAwesomeIcon icon={faInstagram}/>
                    :''}
                </FooterIcon>
                <FooterIconOverlay hoverState={hoverState}></FooterIconOverlay>
           
        </FooterIconSingle>
    );
};

export default FooterIconComponent;