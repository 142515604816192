import React, { useState, useEffect } from 'react';
import styled from "styled-components"
const FooterText = styled.p`
    font-family: Spartan;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left:${props => props.left ? props.left : '0'};
    margin-bottom:${props => props.bottom ? props.bottom : '1.45em'};
    @media screen and (max-width: 479px) {
        font-size: 18px;
        line-height: 23px;
    }
`;
const SingleLocation = styled.div`
    display:flex;
    flex-direction:row;
    position:relative;
    align-items: center;
    margin:10px 0 10px 0;
    &:nth-child(1){
        margin:0px 0 10px 0;
    }
    &:nth-child(4){
        margin:10px 0 0px 0;
    }
    @media screen and (max-width: 991px) {
        margin:10px 0 10px 0;
    }
    
`;
const LocationBorder = styled.div`
    width:${props => props.hoverState ? '5px' : '0px'};
    height:${props => props.hoverState ? '25px' : '0px'};
    background:white;
    position:absolute;
    left:0;
    right:auto;
    transition:all 0.3s ease;
    @media screen and (max-width: 479px) {
        width:5px;
        height:25px;
    }
`;
const FooterSingleLocation = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverIn = () => {
        setHoverState(true)
    }
    const toggleHoverOut = () => {
        setHoverState(false)
    }
    useEffect(() => {
        setHoverState(false)
    }, []);
    return (
        <SingleLocation>
            <LocationBorder hoverState={hoverState}></LocationBorder>
            <FooterText left={'15px'} bottom={'0px'} onMouseOver={toggleHoverIn} onMouseOut={toggleHoverOut}>{props.children}</FooterText>
        </SingleLocation>
    );
};

export default FooterSingleLocation;