import React from 'react';
import { Link } from "gatsby"
import { useState ,useEffect } from 'react';
import styled from 'styled-components'
const NavLinkContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  position:relative;
`;
// const NavLinkBorder = styled.div`
//     height:${props => props.hoverState? '30px':'0px'};
//     background:#3c57a1;
//     width:${props => props.hoverState? '5px':'0px'};
//     transition:all 0.3s ease;
//     position:absolute;
//     left:0;
//     top:auto;
//     bottom:auto;
// `;
const Navlink = styled(Link)`
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 60px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    transition:all 0.3s ease;
    cursor:pointer;
    padding-left:8px;
    text-decoration:none;
    
    &::before{
        content:'';
        position:absolute;
        background:#3c57a1;
        width:0px;
        height:0px;
        left:0;
        top:auto;
        bottom:auto;
        opacity:1;
        transition:all 0.5s ease;
    }
    &.activeNavLink::before{
        content:'';
        position:absolute;
        background:#3c57a1;
        width:5px;
        height:30px;
        left:0;
        top:auto;
        bottom:auto;
    }
    &.activeNavLink{
        opacity:1;
    }
    &:hover{
      opacity:1;
    }
    &:hover::before{
        content:'';
        position:absolute;
        background:#3c57a1;
        width:5px;
        height:30px;
        left:0;
        top:auto;
        bottom:auto;
        transition:all 0.5s ease;
    }
`;

const NavLink = (props) => { 
    const [hoverState, setHoverState] = useState(false);
    const toggleHover= () =>{
        setHoverState(!hoverState)
    }
    useEffect(() => {
        setHoverState(false)
      }, []);
    return (
        <NavLinkContainer>
            {/* <NavLinkBorder hoverState={hoverState}/> */}
            <Navlink to={props.to+"/"} onMouseEnter={toggleHover} onMouseLeave={toggleHover} activeClassName={"activeNavLink"} partiallyActive={true}>
                {props.children}
            </Navlink>
        </NavLinkContainer>
    );
};

export default NavLink;