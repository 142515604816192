import React from 'react';
import styled from "styled-components"
import { Link } from "gatsby"
import FooterIcon from "../components/FooterIcon"
import FooterSingleLocation from "../components/FooterSingleLocation"
const FooterContainer = styled.div`
    height:auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding:20px 2.5vw 20px 2.5vw;
    @media screen and (max-width: 479px) {
        flex-direction:column;
        height:auto;
        align-items: flex-start;
        padding:50px 5% 20px 5%;
    }
`;

const FooterText = styled.p`
    font-family: Spartan;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left:${props => props.left ? props.left : '0'};
    margin-bottom:${props => props.bottom ? props.bottom : '1.45em'};
    &.tagline{
        margin-bottom:10px;
    }
    @media screen and (max-width: 479px) {
        font-size: 18px;
        line-height: 23px;
        &.tagline{
            margin-bottom:30px;
        }
    }
    
`;
const FooterIconContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:flex-start;
    margin-bottom:10px;
`;
const FooterTextBlock = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;
  align-items:flex-start;
  height:auto;
  @media screen and (max-width: 479px) {
        height:auto;
    }
`;
const LocationBlock = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 0.1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    display:grid;
    margin-left:auto;
    @media screen and (max-width: 479px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        flex-direction:column;
        height:auto;
        align-items: flex-start;
        margin:0px 0px 20px 0px;
    }
`;
const LocationContent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-start;
    margin-left:auto;
    height:auto;
    padding:10px 0 10px 0;
    @media screen and (max-width: 479px) {
        height:auto;
        padding:0px;
        margin-left:0px;
        justify-content:flex-start;
    }
`;

const LocationDivider = styled.div`
    height:100%;
    width:2px;
    background:rgba(255,255,255,0.6);
    margin:0 20px 0 40px;
    @media screen and (max-width: 479px) {
        display:none;
    }
`;

const LocationContainer = styled.div`
    display:flex;
    flex-direction:column;
    position:relative;
    height:100%;
    justify-content: space-between;
`;
const CopyrightWrap = styled.div`
    display:flex;
    flex-direction:column;
    position:relative;
    height:auto;
    width:100%;
    padding:0 0vw 10px 0vw;
    @media screen and (max-width: 479px) {
        flex-direction:column;
        height:auto;
        align-items: flex-start;
        padding:20px 0 50px 0;
    }
`;
const NavLink = styled(Link)`
    text-decoration:none;
    font-family: Spartan;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin:0;
    cursor:pointer;
    
`;
const CopyrightText = styled.div`
    font-family: Spartan;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin:20px 0 0 0;
    @media screen and (max-width: 479px) {
        margin:50px 0 0 0;
    }
`;
const Footer = (props) => {
    return (
        <div style={{ background: "#3C57A1" }}>
            <FooterContainer>
                <FooterTextBlock>
                    <FooterText className="tagline">Building the Future of <br />Healthcare Delivery</FooterText>
                    <FooterIconContainer>
                        {/* <FooterIcon icon={"faPhoneAlt"}></FooterIcon> */}
                        <FooterIcon icon={"faEnvelope"} to={"hello@whealthventures.com"}></FooterIcon>
                        <FooterIcon icon={"faLinkedin"} to={"https://www.linkedin.com/company/whealthventures"}></FooterIcon>
                        {/* <FooterIcon icon={"faInstagram"}></FooterIcon> */}
                    </FooterIconContainer>
                    <CopyrightWrap>
                        <NavLink to={"/legal"}>Legal</NavLink>
                        <CopyrightText>W Health Ventures © 2021</CopyrightText>
                    </CopyrightWrap>
                </FooterTextBlock>
                <LocationBlock>
                    <LocationContent>
                        <FooterText>Our locations</FooterText>
                    </LocationContent>
                    <LocationDivider></LocationDivider>
                    <LocationContent>
                        <LocationContainer>
                            <FooterSingleLocation>Pittsburgh, PA</FooterSingleLocation>
                            <FooterSingleLocation>Boston, MA</FooterSingleLocation>
                            <FooterSingleLocation>Mumbai, IN</FooterSingleLocation>
                            <FooterSingleLocation>Delhi, IN</FooterSingleLocation>
                        </LocationContainer>
                    </LocationContent>
                </LocationBlock>
            </FooterContainer>

        </div>
    );
};

export default Footer;