/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./Footer"
import "./layout.css"
// import "@fontsource/montserrat"
// import "@fontsource/roboto" 
import "../css/typography.css"
import styled from "styled-components"
const Body = styled.div`
  width:100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1920px) {
    max-width:1366px;
}
`;
const Layout = props => {
  const { background, children } = props;
  return (
    <Body>
      <Header background={background} />
      <main>{children}</main>
      <Footer></Footer>
    </Body>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
