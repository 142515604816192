import * as React from "react"
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import Navlink from "../components/NavLink"

const NavigationRow = styled.div`
    position:sticky;
    top:0;
    display:flex;
    width:100%;
    padding-left:2.5%;
    padding-right:2.5%;
    z-index:100;
    @media screen and (min-width: 1920px) {
      padding-left:0%;
      padding-right:0%;
    }
`;
const NavigationBlock = styled.div`
    width:50%;
    height:auto;
    display:flex;
    justify-content:flex-end;
    margin-left: auto;
`;
const NavigationWrap = styled.div`
  width:${props => props.hamburgerState ? '290px' : '80px'};
  height: ${props => props.hamburgerState ? '430px' : '80px'};
  border-top-left-radius: ${props => props.hamburgerState ? '0px' : '50px'};
  border-bottom-left-radius: ${props => props.hamburgerState ? '0px' : '50px'};
  background: ${props => props.hamburgerState ? props.background ? props.background : '#eaf3f9' : '#f8f8f8'};
  position:fixed;
  top:20px;
  margin-top:20px;
  display:flex;
  margin-left:auto;
  flex-direction:row;
  justify-content:flex-end;
  align-items:center;
  transition:all 0.5s ease;
  overflow:hidden;
  cursor:${props => props.hamburgerState ? 'auto' : 'pointer'};
  @media screen and (max-width: 479px) {
    margin-top:10px;
    top:10px;
    background: ${props => props.hamburgerState ? '#eaf3f9' : '#f8f8f8'};
    width:${props => props.hamburgerState ? '100%' : '60px'};
    height: ${props => props.hamburgerState ? '430px' : '60px'};
  }
`;
const HamburgerMenu = styled.div`
    width:32px;
    height:41px;
    display:flex;
    flex-direction:column;
    cursor:pointer;
    position: absolute;
    top: 20px;
    left: auto;
    right: 20px;
    z-index:1000;
    @media screen and (max-width: 479px) {
      height: 30px;
      width:28px;
      right: 10px;
      top:15px;
    }
`;
const TopLine = styled.div`
    position:absolute;
    width:100%;
    height: ${props => props.hamburgerState ? '2px' : '11px'};
    transform:${props => props.hamburgerState ? 'rotate(-45deg) translate(0px, 11px)' : 'rotate(0deg) translate(0px,0px)'};
    background:#3c57a1;
    border-radius:50px;
    top:0px;
    transition:all 0.5s ease;
    @media screen and (max-width: 479px) {
      height: ${props => props.hamburgerState ? '2px' : '7px'};
      transform:${props => props.hamburgerState ? 'rotate(-45deg) translate(0px, 8px)' : 'rotate(0deg) translate(0px,0px)'};
    }
`;
const MiddleLine = styled.div`
    position:absolute;
    width:100%;
    height: ${props => props.hamburgerState ? '2px' : '11px'};
    transform:${props => props.hamburgerState ? 'rotate(45deg) translate(0px, -11px)' : 'rotate(0deg) translate(0px,0px)'};
    background:#3c57a1;
    border-radius:50px;
    top:15px;
    transition:all 0.5s ease;
    @media screen and (max-width: 479px) {
      top:11.5px;
      height: ${props => props.hamburgerState ? '2px' : '7px'};
      transform:${props => props.hamburgerState ? 'rotate(45deg) translate(0px, -8px)' : 'rotate(0deg) translate(0px,0px)'};
    }
`;
const BottomLine = styled.div`
    position:absolute;
    width: ${props => props.hamburgerState ? '50%' : '50%'};
    height: ${props => props.hamburgerState ? '2px' : '11px'};
    transform:${props => props.hamburgerState ? 'translate(0px, -5px)' : 'translate(0px,0px)'};
    background:#3c57a1;
    border-radius:50px;
    top:30px;
    left:auto;
    right:0px;
    transition:all 0.5s ease;
    @media screen and (max-width: 479px) {
      top:23px;
      width: ${props => props.hamburgerState ? '60%' : '50%'};
      height: ${props => props.hamburgerState ? '2px' : '7px'};
      transform:${props => props.hamburgerState ? 'translate(0px, 0px)' : 'translate(0px,0px)'};
    }
`;
const NavWrap = styled.div`
    width:${props => props.hamburgerState ? '100%' : '0%'};
    height:${props => props.hamburgerState ? '100%' : '0%'};
    padding:42px 34px;
    display:flex;
    flex-direction:column;
    transform:${props => props.hamburgerState ? 'scale(1)' : 'scale(0.1)'};
    transition:all 0.4s ease;    
    z-index:10;
    opacity:${props => props.hamburgerState ? '1' : '0'};
    margin-top:0px;
    margin-bottom:auto;
    overflow:hidden;
`;
const Header = (props) => {
  const [hamburgerState, setHamburgerState] = useState(false);
  const toggle = () => {
    setHamburgerState(!hamburgerState)
  }
  useEffect(() => {
    setHamburgerState(false)
  }, []);
  return (
    <NavigationRow>
      <NavigationBlock>
        <NavigationWrap hamburgerState={hamburgerState} background={props.background} onClick={toggle}>
          <HamburgerMenu>
            <TopLine hamburgerState={hamburgerState}></TopLine>
            <MiddleLine hamburgerState={hamburgerState}></MiddleLine>
            <BottomLine hamburgerState={hamburgerState}></BottomLine>
          </HamburgerMenu>
          <NavWrap hamburgerState={hamburgerState}>
            <Navlink to="/about-us">About Us</Navlink>
            <Navlink to="/portfolio">Portfolio</Navlink>
            <Navlink to="/team">Team</Navlink>
            <Navlink to="/resources">Resources</Navlink>
            <Navlink to="/pitch-to-us">Pitch to Us</Navlink>
            <Navlink to="/career">Careers</Navlink>
          </NavWrap>
        </NavigationWrap>
      </NavigationBlock>
    </NavigationRow>
  );
}
export default Header
